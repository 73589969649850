$(document).ready(function() {
  $.datepicker.regional['ja'] = {
    closeText: "閉じる",
    prevText: "&#x3C;前",
    nextText: "次&#x3E;",
    currentText: "今日",
    monthNames: [ "1月","2月","3月","4月","5月","6月",
      "7月","8月","9月","10月","11月","12月" ],
    monthNamesShort: [ "1月","2月","3月","4月","5月","6月",
      "7月","8月","9月","10月","11月","12月" ],
    dayNames: [ "日曜日","月曜日","火曜日","水曜日","木曜日","金曜日","土曜日" ],
    dayNamesShort: [ "日","月","火","水","木","金","土" ],
    dayNamesMin: [ "日","月","火","水","木","金","土" ],
    weekHeader: "週",
    dateFormat: "yy/mm/dd",
    firstDay: 0,
    isRTL: false,
    showMonthAfterYear: true,
    yearSuffix: "年",
    minDate: new Date()
  };
  $.datepicker.setDefaults($.datepicker.regional['ja']);
  $('#search_shop_date, #sp_search_shop_date').datepicker({
    onSelect: function (e) {
      handleDate(e)
    },
  });
  $('#date').datepicker({
    onSelect: function(e) {
      $(this).trigger('changeDate');
    },
  });

  $('#clear_search, #clear_search_sp').on('click', (e) => {
    $(e.target).closest('form').find('.form_input').val('');
    $('#sp_area option').remove();
    $('#area option').remove();
    $("#sp_area").append($('<option>').html('選択してください').val(''));
    $("#area").append($('<option>').html('選択してください').val(''));
  });

  $('#frm-search-shop, #frm-search-shop-sp').submit( (e) => {
    $('<input />').attr('type', 'hidden')
      .attr('name', 'sort')
      .attr('value', $('.search-shop-result #sort').val())
      .appendTo(e.target);
    return true;
  });

  $('.search-shop-result #sort').on('change', () => {
    $('#frm-search-shop').submit();
  })
  function handleDate(e) {
    var side_menu_form = $("#search_shop_date, #sp_search_shop_date").closest("form").attr('id');
      $("#search_shop_date").val(e);
      $("#sp_search_shop_date").val(e);
     if (side_menu_form == 'frm-search-shop-sp' || side_menu_form == 'frm-search-shop')
     {
       $("#frm-search-shop-sp").valid();
       $("#frm-search-shop").valid();
     }
  }

  function fetchCourseData(options = {}) {
    return new Promise((resolve, reject) => {
      jQuery.ajax({
        type: "get",
        url: `/shops/courses`,
        data: {
          "shop_id":  $("#bespo_shop_id").val(),
          "number_of_people": options.number_of_people,
          "date": options.date
        },
        success: function (res) {
          resolve(res.courses || []);
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          resolve([]);
        }
      });
    });
  }

  async function getCourses() {
    $("#loading_course").show();
    const courses = await fetchCourseData();
    $("#loading_course").hide();
    if (courses.length == 0) {
      $("#titleCourse").hide();
    } else {
      $("#titleCourse").show();
    }
    courses.forEach(course => {
      const courseElement = $(`
        <div class="container-course">
          <div class="img-course-container">
            <img src="${course.first_image}">
          </div>
          <div class="content-course">
            <div class="category-container">${
              course.categories.map(c => `<span class="category-item">${c.name}</span>`).join('')
            }</div>
            <div class="name-course">${escapeHTML(course.name)}</div>
            <span class="price-course">${changeFormatPrice(course.price)}円 <span class="tax-content">${$("#txt_tax").val()}</span></span>
            <span class="btn-detail-course">${$("#txt_button_detail_course").val()}</span>
          </div>
        </div>
      `);
      courseElement.find('.btn-detail-course').on('click', () => {
        openPopupDetailCourse(course)
      });
      $("#listCourses").append(courseElement);
    });
  }

  function emptyPopup() {
    $("#nameCourse").empty();
    $("#prCourse").empty();
    $("#priceCourse").empty();
    $("#timePeriod").empty();
    $("#numberCustomerLimit").empty();
    $("#stayTime").empty();
    $("#cookingItems").empty();
    $("#description").empty();
    $("#cancellation_policy").empty();
    $("#imageCourse").empty();
    $(`#courseCategories`).empty();
  }

  function emptyPlanPopup() {
    $("#name_plan").empty();
    $("#pr_plan").empty();
    $("#price_plan").empty();
    $("#timePeriod_plan").empty();
    $("#numberCustomerLimit_plan").empty();
    $("#stayTime_plan").empty();
    $("#cookingItems_plan").empty();
    $("#description_plan").empty();
    $("#cancellation_policy_plan").empty();
    $("#image_plan").empty();
  }

  function openPopupDetailCourse(course) {
    emptyPopup();

    if (course.course_images != null && course.course_images.length > 0) {
      course.course_images.forEach(element => {
        $("#imageCourse").append(`<div class="img-course-modal">
                                  <img src="${element.image_path}" /></div>`)
      })
    } else {
      $("#imageCourse").append(`<div class="img-course-modal">
                                  <img src="${course.first_image}" /></div>`)
    }

    $("#nameCourse").html(`${escapeHTML(course.name)}`)
    const coursePR = course.pr == null ? '' : course.pr;
    $("#prCourse").html(`${escapeHTML(coursePR)}`)
    $("#priceCourse").html(`${changeFormatPrice(course.price)}円 ${$("#txt_tax").val()}`)
    if (course.has_limit_customer) {
      $("#numberCustomerLimit").html(`${course.number_customer_min}～${course.number_customer_max} ${$("#txt_people").val()}`)
    } else {
      $("#numberCustomerLimit").html($("#txt_all_party_size").val())
    }
    if (course.has_reservable_time) {
      $("#timePeriod").html(`${course.reservable_form}～${course.reservable_to}`)
    } else {
      $("#timePeriod").html($("#txt_all_time").val())
    }
    $("#stayTime").html(`${course.stay_time} ${$("#txt_minute").val()}`)
    $("#itemDescription").html(`${escapeHTML(course.item_description == null ? '' : course.item_description)}`)
    $("#description").html(`${escapeHTML(course.description == null ? '' : course.description)}`)
    $("#cancellation_policy").html(`${escapeHTML(course.cancellation_policy == null ? '' : course.cancellation_policy)}`)
    $("#detail-course").modal('show')
    if (course.hide_footer) {
      $("#detail-course .modal-footer").hide();
    } else {
      $("#detail-course .modal-footer").show();
    }
  }

  /**
   *  formate datetime (yyyy/mm/dd hh:mm)
   * @param value {string}
   * @returns {`${number}/${number}/${number}  ${string}: ${string}`}
   */
  const formatDate = (value) => {
    if(!value) return

    const date = new Date(value);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, 0);
    const day = (date.getDate()).toString().padStart(2, 0);
    const hours = date.getHours().toString().padStart(2, 0);
    const minutes = date.getMinutes().toString().padStart(2, 0);

    return `${year}/${month}/${day}  ${hours}:${minutes}`
  }
  function openPopupDetailPlan(plan) {
    emptyPlanPopup()

    if (plan.image != null) {

      $("#image_plan").append(`<div class="img-course-modal">
                                  <img src="${plan.image.url}" /></div>`)
    }

    $("#name_plan").html(`${escapeHTML(plan.name)}`)
    const coursePR = plan.pr == null ? '' : plan.pr;
    $("#pr_plan").html(`${escapeHTML(coursePR)}`)
    $("#price_plan").html(`${changeFormatPrice(plan.price.selling_price)}円 ${$("#txt_tax").val()}&nbsp;${getDiscountAmount(plan.price) !== '' ? '| ' + getDiscountAmount(plan.price) : '' }`)
      $("#numberCustomerLimit_plan").html(`${plan.number_of_people_min}～${plan.number_of_people_max} ${$("#txt_people").val()}`)

    if (plan.usage_time) {
      $("#timePeriod_plan").html(`${plan.usage_time?.start_time}～${plan.usage_time?.end_time}`)
    } else {
      $("#timePeriod_plan").html($("#txt_all_time").val())
    }
    $("#stayTime_plan").html(`${plan.available_length_of_stay} ${$("#txt_minute").val()}`)

    // provision period
    if(plan && plan.offer_period) {
      const { start_time , end_time } = plan.offer_period;
      let reservablePeriod = '';
      const provisionPeriodElement = $("#reservablePeriod_plan");

      if(!start_time && !end_time) {
        // hide the reservablePeriod row if both start_time and end_time is null
        provisionPeriodElement.parent('.info_box').attr('style', 'display: none !important;');
      } else {
        // show the reservablePeriod row if the one of the start_time or end_time is not null
        provisionPeriodElement.parent('.info_box').attr('style', '');
        if(end_time && !start_time) {
          reservablePeriod = `〜 ${formatDate(end_time)}`;
        } else if(start_time && !end_time) {
          reservablePeriod = `${formatDate(start_time)} 〜`;
        } else {
          reservablePeriod = `${formatDate(start_time)} 〜 ${formatDate(end_time)}`;
        }
      }

      provisionPeriodElement.html(reservablePeriod);
    }

    $("#itemDescription_plan").html(`${escapeHTML(plan.item_description == null ? '' : plan.item_description)}`)
    if (plan.explanation && plan.explanation != null) {
      $("#description_plan").closest(".info_box").css("cssText", "display: block !important;");
      $("#description_plan").html(`${escapeHTML(plan.explanation)}`)
    }
    else{
      $("#description_plan").closest(".info_box").css("cssText", "display: none !important;");
    }
    if (plan.notes && plan.notes != null) {
      $("#remarks_plan").closest(".info_box").css("cssText", "display: block !important;");
      $("#remarks_plan").html(`${escapeHTML(plan.notes)}`)
    }
    else{
      $("#remarks_plan").closest(".info_box").css("cssText", "display: none !important;");
    }
    if (plan.all_you_can_drink_menu && plan.all_you_can_drink_menu != null) {
      $("#all_you_can_drink_menu_plan").closest(".info_box").css("cssText", "display: block !important;");
      $("#all_you_can_drink_menu_plan").html(`${escapeHTML(plan.all_you_can_drink_menu)}`)
    }
    else{
      $("#all_you_can_drink_menu_plan").closest(".info_box").css("cssText", "display: none !important;");
    }

    if (plan.plan_related_services && plan.plan_related_services.length > 0){
      $("#tags-container").closest(".info_box").css("cssText", "display: block !important;");
      $("#tags-container").html(`${plan.plan_related_services.map(c => `<span class="category-item">${c.plan_name}</span>`).join('')}`)
    }
    else{
      $("#tags-container").closest(".info_box").css("cssText", "display: none !important;");
    }

    $("#detail-plan").modal('show')
    if (plan.hide_footer) {
      $("#detail-plan .modal-footer").hide();
    } else {
      $("#detail-plan .modal-footer").show();
    }
  }
  function changeFormatPrice(price) {
    return new Intl.NumberFormat('ja-JP').format(price);
  }

  function escapeHTML(htmlStr) {
    if (htmlStr == null) return '';

    return htmlStr.replace(/&/g, "&amp;")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#39;");
  }

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function getCalendar(options = {}) {
    if (document.querySelector('.seat_calendar') === null) return;

    const start_date = options.start_date ? new Date(options.start_date) : new Date();
    const date_range = 14;
    const locale = $("#language").val() === 'ja' ? 'ja-JP' : 'en-US';
    $("#seat_calendar").empty();
    const seat_day_elements = {};
    let month = null;
    const formatDate = (date) => {
      if (month === date.getMonth()) {
        return `${date.getDate()}`
      } else {
        month = date.getMonth();
        return `${date.getMonth()+1}/${date.getDate()}`
      }
    }
    for (let i = 0; i < date_range; i++) {
      const date = addDays(start_date, i);
      const date_element = $(`
        <div class="seat_day">
          <div class="weekday ${date.getDay() === 0 ? 'sun' : '' } ${date.getDay() === 6 ? 'sat' : '' }">${date.toLocaleDateString(locale, {weekday: 'short'})}</div>
          <button disabled>
            <div class="date">${formatDate(date)}</div>
            <div class="availability"></div>
          </button>
        </div>
      `);
      date_element.find('button').on('click', () => {
        openTimetableModal({date: date.yyyymmdd()});
      });
      seat_day_elements[date.yyyymmdd().replace(/\//g, "-")] = date_element;
      $("#seat_calendar").append(date_element);
      if (i === 6) {
        $("#seat_calendar").append('<div class="flex_break"></div>');
      }
    }

    window.calendar_start_date = start_date;
    if (start_date <= new Date()) {
      $(".prev-week").css('visibility', 'hidden');
    } else {
      $(".prev-week").css('visibility', 'visible');
    }

    $("#loading_seat_calendar").show();
    jQuery.ajax({
      type: "get",
      url: `/shops/calendar`,
      data: {
        "shop_id": $("#bespo_shop_id").val(),
        "start_date": start_date.yyyymmdd(),
        "end_date": addDays(start_date, date_range - 1).yyyymmdd(),
      },
      success: function (res) {
        $("#loading_seat_calendar").hide();
        res.data.forEach(e => {
          if (e.reservable) {
            seat_day_elements[e.date]?.find('button').removeAttr('disabled');
            seat_day_elements[e.date]?.find('.availability').addClass('yes');
          } else {
            seat_day_elements[e.date]?.find('button').attr('disabled', true);
            seat_day_elements[e.date]?.find('.availability').addClass('no');
          }
        });
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        $("#loading_seat_calendar").hide();
      }
    });
  }

  function openTimetableModal(options = {}) {
    // redirect to tablecheckweb if needed
    if(document.querySelector('#redirect_to_tablecheckweb').value === 'true') {
      jQuery.ajax({
        type: "post",
        url: `/shops/tablecheckweb`,
        data: {
          "id_code": $("#shop_id_code").val(),
          "date": options.date || null,
          "from_fb_insta": $("#from_fb_insta").val(),
        },
        success: function (res) {
          location.href = res.url;
        },
      });
      return;
    }

    // set default input
    const defaultDate = options.date || new Date().yyyymmdd();
    const defaultPerson = options.number_of_people || 2;
    $("#date").val(defaultDate);
    $("#number_of_people").val(defaultPerson);

    // reset timetable
    $("#seat-list").empty();
    $("#not-availability").hide();
    $("#only-seat-radio").hide();
    $("#list-timetable-courses").empty();
    $("#new-reservation-btn").prop('disabled', true).addClass('disabled');

    $("#modal-timetable").modal('show')
    getTimetableSeats({time: options.time});
  }
  function resetPlanSeatData(){
    $(`input[name="seat_type_id"]`).prop("checked", false);
    $("#seat_name").val('')
  }

  function getTimetableSeats(options = {}) {
    $("#loading_timetable").show();
    jQuery.ajax({
      type: "get",
      url: `/shops/timetable`,
      data: {
        "shop_id": $("#bespo_shop_id").val(),
        "number_of_people": $("#number_of_people").val(),
        "date": $("#date").val(),
      },
      success: function (res) {
        $("#loading_timetable").hide();
        $("#seat-list").empty();
        res.data ||= [];
        if (res.data.length === 0) {
          $("#not-availability").show();
          $("#only-seat-radio").hide();
          $("#only_seat").prop('checked', false);
          $("#list-timetable-courses").empty();
        } else {
          $("#not-availability").hide();
          $("#only-seat-radio").show();
          $("#only_seat").prop('checked', true).trigger('change');
        }
        const seats = res.data.map(time => {
          const date = new Date(time);
          const button =  $(`
            <button type="button" class="time">${date.toTimeString().split(' ')[0].substring(0, 5)}</button>
          `);
          button.on('click', () => {
            $("#seat-list .time").removeClass('active');
            button.addClass('active');
            if($('#shop_type').val()==='usen'){
              getTimetablePlans(time);
            }
            else{
              getTimetableCourses(time)
            }
          });
          return button;
        });
        seats.forEach(seat => {
          $("#seat-list").append(seat);
        });

        // select default time
        if (seats.length > 0) {
          let selectedSeat = seats[Math.floor((seats.length-1)/2)]
          if (options.time) {
            selectedSeat = seats.find(seat => seat.text() === options.time);
          }
          selectedSeat.focus().click();
        }
        activeReservationBtn();
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        $("#loading_timetable").hide();
      }
    });
  }
  function fetchPlanData(options = {}) {
    return new Promise((resolve, reject) => {
      jQuery.ajax({
        type: "get",
        url: '/shops/usen_plans',
        data: {
          "shop_id": $("#bespo_shop_id").val(),
          "number_of_people": options.number_of_people,
          "date": options.date
        },
        success: function (res) {
          resolve(res.usen_plans || []);
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          resolve([]);
        }
      });
    });
  }

  async function getPlans() {
    $("#loading_course").show();
    const plans = await fetchPlanData();
    $("#loading_course").hide();
    if (plans && plans.plans && plans.plans.length === 0) {
      $("#titleCourse").hide();
    } else {
      $("#titleCourse").show();
    }
    plans.plans.forEach(plan => {
      const courseElement = $(`
        <div class="container-course">
          <div class="img-course-container clickable-detail">
            <img src="${plan.image.url}">
          </div>
          <div class="content-course">
            ${renderPlanTags(plan)}
            <div class="name-course clickable-detail" style="margin-bottom:0 !important">${escapeHTML(plan.name)}</div>
            <span class="price-course plan-price">${changeFormatPrice(plan.price.market_price)}円 
              <span class="tax-content">${$("#txt_tax").val()}</span>
              ${ parseFloat(plan.price.market_price) > parseFloat(plan.price.selling_price) ? 
                `<span class='text-price' style="margin-left: 0.4rem;">${changeFormatPrice(plan.price.selling_price)}円<span class="tax-content">${$("#txt_tax").val()}</span></span> 
                <span class='red-color' style="font-weight: 500 !important">&nbsp;${getDiscountAmount(plan.price) !== '' ? '| ' + getDiscountAmount(plan.price) : '' }
                </span>` : ''
              }
              <span class="btn-detail-course clickable-detail">${$("#txt_button_detail_course").val()}</span>
            </span>
          </div>
          <div id="reserve_plan_${plan.id}" class="reserve_plan"></div>
        </div>
      `);
      courseElement.find('.clickable-detail').on('click', () => {
        openPopupDetailPlan(plan);
      });
      $("#listCourses").append(courseElement);
    });
  }

  async function getTimetableCourses(time) {
    $("#loading_timetable_course").show();
    $("#timetable").val(time);
    const courses = await fetchCourseData({date: time, number_of_people: $("#number_of_people").val()});
    $("#loading_timetable_course").hide();
    $("#only_seat").prop('checked', true).trigger('change');
    $("#list-timetable-courses").empty();
    courses.forEach(course => {
      const courseElement = $(`
        <div class="container-course mt_medium">
          <label class="radio-btn-align" for="course-change_${course.id}">
            <input class="radio" id="course-change_${course.id}" type="radio" value="${course.id}" name="course_ids">
            <label class="radio_label cursor-pointer" for="course-change_${course.id}"></label>
          </label>
          <div class="img-course-container">
            <img src="${course.first_image}">
          </div>
          <div class="content-course">
            <div class="category-container">${
              course.categories.map(c => `<span class="category-item">${c.name}</span>`).join('')
            }</div>
            <div class="name-course">${escapeHTML(course.name)}</div>
            <span class="price-course">${changeFormatPrice(course.price)}円 <span class="tax-content">${$("#txt_tax").val()}</span></span>
            <span class="btn-detail-course">${$("#txt_button_detail_course").val()}</span>
          </div>
        </div>
      `);
      courseElement.find('.btn-detail-course').on('click', () => {
        course.hide_footer = true;
        openPopupDetailCourse(course)
      });
      courseElement.find('input[name=course_ids]').on('change', () => {
        $("#name_course").val(course.name);
        $("#course_cancellation_policy").val(course.cancellation_policy);
        activeReservationBtn();
      });
      $("#list-timetable-courses").append(courseElement);
      activeReservationBtn();
    });
  }

  async function getTimetablePlans(time) {
    const timetableCoursesContainer = document.querySelector(".list-timetable-courses")
    const skeletonLoadingContainer = $(".skeleton_loading__inner_container")

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        /**
         * height based on the visible height of the timetable Courses list
         */
        skeletonLoadingContainer.css('height', `${entry.intersectionRect.height}px`)
      });
    }, { threshold: 0 });

    observer.observe(timetableCoursesContainer);

    $("#not-availability").hide();
    $("#loading_timetable_course").show();
    $("#timetable").val(time);
    addSubmitEvent(time)
    const plans = await fetchPlanData({date: time, number_of_people: $("#number_of_people").val()});
    $("#loading_timetable_course").hide();
    $("#list-timetable-courses").empty();
    if (plans && plans.plans && plans.plans.length === 0 ) {
      if (plans.shop_has_plan){
        $("#not-availability").show();
        $("#only-seat-radio").hide();
        $("#only_seat").prop('checked', false);
        $("#new-reservation-btn").prop('disabled', true).addClass('disabled');
      }else{
        $("#only-seat-radio").show();
        $("#not-availability").hide();
        $("#only_seat").prop('checked', true).trigger('change');
        $("#new-reservation-btn").prop('disabled', false).removeClass('disabled');
        $("#form-to-reservation").off("submit");        
      }
    } else {
      $("#only-seat-radio").hide();
      $("#not-availability").hide();
      validateReservationBtnPlan();
    }
    plans.plans.forEach(plan => {
      const planElement = $(`
        <div class="container-course mt_medium">
          <label class="radio-btn-align" for="course-change_${plan.id}">
            <input class="radio" id="plan-change_${plan.id}" type="radio" value="${plan.id}" name="plan_id">
            <label class="radio_label cursor-pointer" for="plan-change_${plan.id}"></label>
          </label>
          <div class="semi-container">
            <div class="img-course-container clickable-detail">
              <img src="${plan.image?.url}">
            </div>
            
            <div class="content-plan">
             ${renderPlanTags(plan)}
             <div class="name-course clickable-detail">${escapeHTML(plan.name)}</div>          
              <span class="price-course plan-price">${changeFormatPrice(plan.price?.market_price)}
              円 <span class="tax-content">${$("#txt_tax").val()}</span>
              ${ parseFloat(plan.price.market_price) > parseFloat(plan.price.selling_price) ?
              `<span class='text-price'>${changeFormatPrice(plan.price.selling_price)} 円 
                  <span class="tax-content">${$("#txt_tax").val()}</span>
                </span>
               <span class='red-color' style="font-weight: 500 !important"> | ${getDiscountAmount(plan.price)}</span> 
                ` : ''
                }
                <span class="btn-detail-course clickable-detail">${$("#txt_button_detail_course").val()}</span>
               </span>
            </div>
            <div id="reserve_plan_${plan.id}" class="reserve_plan">
                <table id="plan_table_${plan.id}">
                </table>
            </div>
           </div>
          </div>
      `);
      plan.seat_types?.length > 0 && plan.seat_types.forEach(seat => {
        const seatElement = $(`
          <tr class="plan_seat" style="margin-bottom:4px">
            <td>
              <input class="radio" id="seat-change_${plan.id}_${seat.id}" type="radio" value="${seat.id}" name="seat_type_id">
              <label class="radio_label cursor-pointer" for="seat-change_${plan.id}_${seat.id}"></label>
            </td>
            <td>
              <span>${seat.name}</span>
            </td>
            ${!plan.price.extra_charge_applicable ? 
              `<td style="padding-left:14px">
                <span>${getSeatCharge(seat)}</span>
              </td>` 
              : ''
            }
          </tr>
        `)

        seatElement.find(`#seat-change_${plan.id}_${seat.id}`).on('click',function(){
          $("#seat_name").val(seat.name)
          $("#seat_charge_fee").val(!plan.price.extra_charge_applicable ? getSeatCharge(seat) : '')
          validateReservationBtnPlan()
        })

        planElement.find(`#plan_table_${plan.id}`).append(seatElement)
      })

      planElement.find('.clickable-detail').on('click', () => {
        plan.hide_footer = true;
        openPopupDetailPlan(plan)
      });
      planElement.find('input[name=plan_id]').on('change', function(){
        $("#plan_id").val(plan.id);
        updatePlanData(plan)
        $('.reserve_plan').css("display", "none");
        planElement.find(`#reserve_plan_${plan.id}`).css("display", "block");
        resetPlanSeatData()
        validateReservationBtnPlan();
      });

      $("#list-timetable-courses").append(planElement);
    });
  }

  function addSubmitEvent(time){
    $("#form-to-reservation").off("submit");
    $("#form-to-reservation").on("submit", async function(event) {
      event.preventDefault();
      const success = await handlePlanData({date: time, number_of_people: $("#number_of_people").val()});
      if (!success) return;
      this.submit()
    })
  }

  function updatePlanData(plan){
    $("#plan_name").val(plan.name);
    $("#plan_notes").val(plan.notes);
    $("#plan_price").val(getPlanPrice(plan));
    $("#free_text_placeholder").val(plan.free_text_initial_text);
    $("#free_text_caption").val(plan.free_text_input_caption);
    $("#free_text_notes").val(plan.free_text_notes);
    $("#free_text_input_type").val(plan.free_text_input_type);
    $("#course_cancellation_policy").val(plan.cancellation_policy);
  }

  function renderPlanTags(plan) {
    const tagMap = {
      jp:{
        popular: '👑 人気No.1',
        recommended: '😃 オススメ'
      },
      en:{
        popular: '👑 No. 1 in popularity',
        recommended: '😃 Recommended'
      }
    };

    return plan?.label ? `<p class='text-primary' style="padding-bottom:4px">${$("#language").val() === 'ja' ? tagMap.jp[plan.label] : tagMap.en[plan.label]}</p>` : '';
  }

  function getDiscountAmount(price){
    if( parseFloat(price.market_price) <= parseFloat(price.selling_price) ){
      return ''
    }

    if (price.discount_symbol == '%'){
      return  Math.round(
        ((parseFloat(price.market_price) -
          parseFloat(price.selling_price)) /
          parseFloat(price.market_price)) *
          100
      ) + '% OFF'
    }
    else{
      return Math.floor(
          parseFloat(price.market_price) -
          parseFloat(price.selling_price)
        ) + '円 OFF'
    }
  }

  function activeReservationBtn() {
    if (($("input[name=course_ids]:checked").val() != null || $("input[name=plan_id]:checked").val() != null) && $("#timetable").val()) {
      $("#new-reservation-btn").prop('disabled', false).removeClass('disabled');
    } else {
      $("#new-reservation-btn").prop('disabled', true).addClass('disabled');
    }
  }

  function validateReservationBtnPlan() {
    const hasPlanSelected = $("input[name=plan_id]:checked").val() != null;
    const hasSeatTypeSelected = $("input[name=seat_type_id]:checked").val() != null;
    const hasTimetable = $("#timetable").val() !== "";
  
    if (hasTimetable && hasPlanSelected && hasSeatTypeSelected) {
      $("#new-reservation-btn").prop('disabled', false).removeClass('disabled');
    } else {
      $("#new-reservation-btn").prop('disabled', true).addClass('disabled');
    }
  }

  function validateReservationBtn(){
    if( $("#timetable").val() == "" || $("#timetable").val() == null || $("#timetable").val() == undefined ){
      $("#new-reservation-btn").prop('disabled', true).addClass('disabled');
    }
    else{
      $("#new-reservation-btn").prop('disabled', false).removeClass('disabled');
    }
  }

  function getPlanPrice(plan){
    let plan_price = plan?.price?.selling_price
    let charge_included = plan?.price?.extra_charge_applicable

    if($("#language").val() === 'ja'){
      return `¥ ${plan_price} (税込) ${charge_included ? 'チャージ料金込み': ''}`
    }
    else {
      return `¥ ${plan_price} (tax included) ${charge_included ? 'Charge fee included': ''}`
    }
  }

  function getSeatCharge(seat) {
    const language = $("#language").val() === 'ja' ? 'jp' : 'en';
    
    const translations = {
      jp: {
        chargePerTable: 'チャージ料金：１テーブルあたり',
        chargePerPerson: 'チャージ料金：お一人様',
        noCharge: 'チャージ料金：なし',
        taxIncluded: '（税込）'
      },
      en: {
        chargePerTable: 'Charge fee: Per table ',
        chargePerPerson: 'Charge fee: Per person ',
        noCharge: 'Charge fee: No charge',
        taxIncluded: ' (tax included)'
      }
    };

    const t = translations[language];
    if (seat?.charge_fee == 0 || seat?.charge_fee == "0") {
      return t.noCharge
    }
    switch (seat.charge_type) {
      case 'per_table':
        return seat.charge_fee_type === "percent" 
          ? `${t.chargePerTable}${seat?.charge_fee}%`
          : `${t.chargePerTable}¥${seat?.charge_fee}${t.taxIncluded}`;
      case 'per_person':
        return seat.charge_fee_type === "percent"
          ? `${t.chargePerPerson}${seat?.charge_fee}%`
          : `${t.chargePerPerson}¥${seat?.charge_fee}${t.taxIncluded}`;
      default:
        return t.noCharge;
    }
  }

   async function handlePlanData(planData){
    try{
      const plans = await fetchPlanData({date: planData.date, number_of_people: planData.number_of_people});
      const plan_id = $("#plan_id").val();
      const plan = plans.plans.find(plan => plan.id == plan_id);
      updatePlanData(plan);
      return true
    }
    catch(error){
      return false
    }
  }

  // For shops/:id page
  if($("#page-shop-show").length > 0) {
    $(".open-timetable-modal").on('click', () => {
      $("#detail-course").modal('hide');
      $("#detail-plan").modal('hide');
      openTimetableModal();
    });

    $(".seat_day").on('click', (e) => {
      openTimetableModal({date: $(e.currentTarget).data('date')});
    });

    $(".reload-timetable").on('change changeDate', getTimetableSeats);

    $("#only_seat").on('change', () => {
      $("#name_course").val('');
      $("#course_cancellation_policy").val('');
      $("#plan_id").val('');
      $("#plan_name").val('');
      $("#plan_price").val('');
      $("#free_text").val('');
      $("#free_text_caption").val('');
      $("#free_text_notes").val('');
      $("#free_text_input_type").val('');
      $("#free_text_placeholder").val('');
      resetPlanSeatData()
      validateReservationBtn()
      $('.reserve_plan').hide()
    });

    $(".prev-week").on('click', () => {
      getCalendar({start_date: addDays(window.calendar_start_date, -14)});
    });
    $(".next-week").on('click', () => {
      getCalendar({start_date: addDays(window.calendar_start_date, 14)});
    });

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('access') === 'list') {
      const date = urlParams.get('date');
      const time = urlParams.get('time');
      const number_of_people = urlParams.get('number_of_people');
      openTimetableModal({date: date, time: time, number_of_people: number_of_people});
    }

    getCalendar();
    if($('#shop_type').val()==='usen'){
      getPlans()
    }
    else{
      getCourses();
    }
  }
});
